import React, { FC, useLayoutEffect, useState } from 'react';
import { roundingNumber } from 'services/utils/roundingNumber';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { popUpOpen, setPopUpData } from '../../../../../../redux/reducers/popUp/reducer';
import { ROUTES } from '../../../../../../routes/constants';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
}
//
// export interface IItem {
// 	id: number;
// 	balance_id: number;
// 	chain_id: number;
// 	asset_id: number;
// 	nickname: string;
// 	address?: any;
// 	segwit_address: string;
// 	tag?: any;
// 	type: string;
// 	fireblocks_balance: number;
// 	created_at: string;
// 	updated_at: string;
// 	code: string;
// 	code_b2c2: string;
// 	code_ftx?: any;
// 	active: number;
// 	view_decimal: number;
// 	depositable: number;
// 	withdrawable: number;
// 	exchangeable: number;
// 	depositable_message?: any;
// 	withdrawable_message?: any;
// 	has_tag: number;
// 	chain: string;
// 	decimals: string;
// 	public?: any;
// 	private?: any;
// 	name: string;
// 	cold_wallet_enabled: number;
// 	hot_wallet_address?: any;
// 	cold_wallet_address?: any;
// 	deposit_max: number;
// 	deposit_min: number;
// 	withdraw_max: number;
// 	withdraw_min: number;
// 	exchange_min: number;
// 	link: string;
// 	user_id: number;
// 	balance: number;
// 	frozen_balance: number;
// 	top_up_address?: any;
// 	email: string;
// 	email_confirmed: number;
// 	password: string;
// 	google2fa_secret: string;
// 	google2fa_enabled: number;
// 	user_data_approved: number;
// 	last_login: string;
// 	blocked: number;
// 	status_id: number;
// 	last_activity: string;
// 	invite_key: string;
// 	remember_token?: any;
// 	is_active: number;
// 	is_archived: number;
// 	confirm_token?: any;
// 	parent_user_id: string;
// 	legacy_id: string;
// 	first_name: string;
// 	last_name: string;
// 	phone: string;
// 	account_number?: any;
// 	position?: any;
// 	communication_channel: string;
// 	communication_data: string;
// 	deposit_bank_account_id: number;
// 	fireblocks_vault_id: string;
// 	liquidity_provider: string;
// 	company_name: string;
// 	company_country_registration: string;
// 	company_registration_number?: any;
// 	company_website: string;
// 	bank_nickname: string;
// 	bank_account_number: string;
// 	bank_swift_bic: string;
// 	bank_beneficiary_name: string;
// 	bank_beneficiary_address: string;
// 	bank_currency: string;
// 	commission: number;
// 	download_secret_code?: any;
// }
/* eslint-disable @typescript-eslint/naming-convention */

const Item: FC<any> = ({ itemList, permission }) => {
	const dispatch = useDispatch();

	return (
		<>
			{itemList?.length
				? itemList?.map((e: any) => {
						return (
							<div key={`${String(e?.id)}  ${uuidv4()}`} className="tr">
								<div className="td">
									<p className="td-hidden-name">User ID</p>
									<div className="td__wrap">
										<div className="td__text">
											<Link to={`${ROUTES.userManagement.index}/${String(e?.user_id)}`}>
												{e?.user_id}
											</Link>
										</div>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Full name</p>
									<div className="td__wrap">
										<div className="td__text">
											{e?.first_name} {e?.last_name}
										</div>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Email</p>
									<div className="td__wrap">
										<div className="td__text">
											<Link to={`${ROUTES.userManagement.index}/${String(e?.user_id)}`}>
												{e?.email}
											</Link>
										</div>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Company name</p>
									<div className="td__wrap">
										<div className="td__text">{e?.company_name ? e?.company_name : '-'}</div>
									</div>
								</div>
								<div className="td td--right">
									<p className="td-hidden-name">Currency</p>

									<div className="td__text">{e?.asset?.code?.toUpperCase()}</div>
								</div>
								<div className="td td--right">
									<p className="td-hidden-name">Balance</p>

									<div className="td__text">
										{roundingNumber(e?.balance, e?.code)} {e?.code?.toUpperCase()}
									</div>
									<div className="td__more">≈ {roundingNumber(e?.balance_eur)} EUR</div>
								</div>
							</div>
						);
				  })
				: ''}
		</>
	);
};

export default Item;
