/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import {
	EApiFiatTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	EPendingWithdrawalType,
} from 'services/api/transactions/types';
import {
	putFiatTransactionsRequest,
	putPendingDepositApproveRequest,
	putPendingDepositRejectRequest,
	putPendingWithdrawalNoteRequest,
} from 'redux/reducers/transactions/reducer';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import { roundingNumber } from 'services/utils/roundingNumber';
import { shortNotation } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';

const STATUS_TO_EDIT_CRYPTO = [
	{
		id: 1,
		name: 'Completed',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
];
const STATUS_TO_EDIT_FIAT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiFiatTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Cancelled',
		value: EApiFiatTransactionStatus.CANCELED,
	},
];

export interface IPendingDepositsTableRow {
	id: number;
	status: {
		id: number;
		status: any;
		created_at: string;
		updated_at: string;
	};
	created_at: string;
	asset: {
		id: number;
		code: string;
		type: string;
		depositable: number;
		withdrawable: number;
		exchangeable: number;
		depositable_message: string | null;
		withdrawable_message: string | null;
		has_tag: number;
		decimals: string;
		name: string;
		deposit_max: number;
		deposit_min: number;
		withdraw_max: number;
		withdraw_min: number;
		exchange_min: number;
		img_path: string;
	};
	amount: number;
	type: string | null;
	assetType: any;
	permission: any;
	client_notes: string | null;
	admin_id: number;
	user_id: number;
	tx_hash: string;
	net_fee: number;
	gross_fee: number;
	fee: number;
	address: any;
	destination: string | null;
}

const PendingDepositsTableRow: FC<any> = ({
	id,
	status,
	created_at,
	asset,
	amount,
	type,
	// user,
	permission,
	client_notes,
	assetType,
	// admin,
	tx_hash,
	ext_tx_hash,
	admin_id,
	user_id,
	net_fee,
	gross_fee,
	fee,
	address,
	destination,
	transactions_fiats,
	transactions_cryptos,
}) => {
	const dispatch = useDispatch();
	const [dateNow] = useFormatDateTime(new Date().toISOString());
	const [date, time] = useFormatDateTime(created_at);
	const { path } = useRouteMatch();

	const statusToEditArray =
		assetType === EPendingWithdrawalType.FIAT ? STATUS_TO_EDIT_FIAT : STATUS_TO_EDIT_CRYPTO;

	const filterStatusArrayToEdit = (currentStatus: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return statusToEditArray.filter((el) => el.value !== currentStatus);
	};
	const findEditedStatus = (currentStatus: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return statusToEditArray.find((el) => el.value === currentStatus) || statusToEditArray[0];
	};

	const [editedGross, setEditedGross] = useState<number | undefined>(gross_fee);
	const handleEditGross = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedGross(Number(event.target.value));
	};
	const [editedFee, setEditedFee] = useState<number | undefined>(fee);
	const handleEditFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedFee(Number(event.target.value));
	};

	const [editedNote, setEditedNote] = useState(client_notes);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value || null);
	};

	const defaultStatus = {
		id: 0,
		value:
			assetType === EPendingWithdrawalType.CRYPTO
				? EApiPooledDepositTransactionStatus.ADMIN_PENDING
				: 'pending',
		name: 'Pending',
	};

	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>(defaultStatus);
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status.status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => {
		setEditMode(true);
	};
	const disableEditMode = () => {
		setEditedFee(fee);
		setEditedGross(gross_fee);
		setEditMode(false);
	};

	const handleEdit = () => {
		if (assetType === EPendingWithdrawalType.CRYPTO) {
			const payload = {
				id,
				type: assetType,
			};
			if (editedStatus.value === 'completed') {
				dispatch(putPendingDepositApproveRequest(payload));
			} else {
				dispatch(putPendingDepositRejectRequest(payload));
			}
		} else {
			dispatch(
				putFiatTransactionsRequest({
					data: {
						date: dateNow,
						fee: Number(editedFee),
						indicated_amount: editedGross,
						status: editedStatus.value,
						note: editedNote || '',
						admin_requisite_id: transactions_fiats?.admin_requisite?.id,
					},
					type: assetType,
					id,
					status: 'pending',
				}),
			);
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		// setEditedStatus(findEditedStatus(status.status));
		setEditedStatus(defaultStatus);
		setStatusArrayToEdit(statusToEditArray);
		disableEditMode();
	};

	const convertStatusValue = (currentStatus: EApiTransactionStatus) => {
		switch (currentStatus) {
			case EApiTransactionStatus.FAILED:
				return 'Failed';
			case EApiTransactionStatus.CANCELED:
				return 'Cancelled';
			case EApiTransactionStatus.REJECTED:
				return 'Rejected';
			case EApiTransactionStatus.ADMIN_PENDING:
				return 'Pending';
			case EApiTransactionStatus.PENDING_SIGNING:
				return 'Pending Signing';
			case EApiTransactionStatus.SUCCESS:
				return 'Completed';
			case EApiTransactionStatus.COMPLETED:
				return 'Completed';
			case EApiTransactionStatus.BROADCASTING:
				return 'Broadcasting';
			default:
				return `${currentStatus[0].toUpperCase()}${currentStatus.slice(1)}`;
		}
	};
	// const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	// const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);
	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{assetType === EPendingWithdrawalType.FIAT ? user_id : user_id || '-'}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{asset.code.toUpperCase()}</p>
				<span className="td-more-info">{asset?.name}</span>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Gross</p>
				{editMode ? (
					<input
						type="number"
						placeholder="0"
						name="indicated_amount"
						value={editedGross || ''}
						onChange={handleEditGross}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<>
						<p>{roundingNumber(gross_fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				{editMode ? (
					<>
						<input
							type="number"
							placeholder="0"
							name="fee"
							value={editedFee || ''}
							onChange={handleEditFee}
							className="input-item input--table-item input-item---fullWidth input-item--type-td"
						/>
					</>
				) : (
					<>
						<p>{roundingNumber(fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Net</p>
				<p>{roundingNumber(net_fee, asset.code)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Destination</p>
				{assetType === EPendingWithdrawalType.CRYPTO ? (
					<p className="copy-button">
						{destination && (
							<>
								<span className="copy-button__text copy-button__text--max-width">
									{shortNotation(destination, 4, 4) || '-'}
								</span>
								<CopyToClipboard text={destination.toString()} onCopy={handleCopy}>
									<button type="button">
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
												fill="#5974FF"
											/>
										</svg>
										Copy
									</button>
								</CopyToClipboard>
							</>
						)}
						{!destination && transactions_cryptos && transactions_cryptos.address?.address && (
							<>
								<span className="copy-button__text copy-button__text--max-width">
									{shortNotation(transactions_cryptos.address?.address, 4, 4) || '-'}
								</span>
								<CopyToClipboard
									text={transactions_cryptos.address?.address.toString()}
									onCopy={handleCopy}
								>
									<button type="button">
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
												fill="#5974FF"
											/>
										</svg>
										Copy
									</button>
								</CopyToClipboard>
							</>
						)}
						{!destination && !transactions_cryptos && (
							<span className="copy-button__text copy-button__text--max-width">-</span>
						)}
					</p>
				) : (
					<>
						{transactions_fiats && transactions_fiats.admin_requisite && (
							<>
								<span className="tooltip-wrap">
									<p>{transactions_fiats.admin_requisite?.bank_name || '-'}</p>

									{transactions_fiats.admin_requisite?.bank_name && (
										<span className="tooltip-item">
											<span className="tooltip-item__title">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="15"
													height="14"
													viewBox="0 0 15 14"
													fill="none"
												>
													<path
														d="M7.20003 12.8307C8.81085 12.8307 10.2692 12.1778 11.3248 11.1222C12.3804 10.0665 13.0334 8.60821 13.0334 6.9974C13.0334 5.38658 12.3804 3.92825 11.3248 2.8726C10.2692 1.81698 8.81085 1.16406 7.20003 1.16406C5.58922 1.16406 4.13088 1.81698 3.07524 2.8726C2.01962 3.92825 1.3667 5.38658 1.3667 6.9974C1.3667 8.60821 2.01962 10.0665 3.07524 11.1222C4.13088 12.1778 5.58922 12.8307 7.20003 12.8307Z"
														stroke="#5974FF"
														strokeLinejoin="round"
													/>
													<path
														d="M7.20036 3.71094C7.32692 3.71094 7.42952 3.81354 7.42952 3.9401C7.42952 4.06667 7.32692 4.16927 7.20036 4.16927C7.0738 4.16927 6.97119 4.06667 6.97119 3.9401C6.97119 3.81354 7.0738 3.71094 7.20036 3.71094Z"
														fill="#5974FF"
														stroke="#5974FF"
													/>
													<path
														d="M7.34603 9.91927V5.83594H7.05436H6.7627"
														stroke="#5974FF"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M6.3252 9.91406H8.36686"
														stroke="#5974FF"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
											<span className="tooltip tooltip--width-720 tooltip--bottom">
												<span className="bank-info">
													<span className="bank-info bank-info--title">
														{transactions_fiats.admin_requisite?.bank_name}
													</span>
													<span className="bank-info bank-info--list-wrapper">
														<span className="bank-info bank-info--list">
															<span className="bank-info bank-info--item">
																<span>Beneficiary Name</span>
																<p>{transactions_fiats.admin_requisite?.beneficiary_name}</p>
															</span>
															<span className="bank-info bank-info--item">
																<span>Beneficiary Address</span>
																<p>{transactions_fiats.admin_requisite?.beneficiary_address}</p>
															</span>
															<span className="bank-info bank-info--item">
																<span>Beneficiary Country</span>
																<p>
																	{transactions_fiats.admin_requisite?.beneficiary_country || '-'}
																</p>
															</span>
															<span className="bank-info bank-info--item">
																<span>Bank Name</span>
																<p>{transactions_fiats.admin_requisite?.bank_name}</p>
															</span>
														</span>
														<span className="bank-info-list bank-info--list">
															<span className="bank-info  bank-info--item">
																<span>Bank Address</span>
																<p>{transactions_fiats.admin_requisite?.bank_address}</p>
															</span>
															<span className="bank-info  bank-info--item">
																<span>Bank Country</span>
																<p>{transactions_fiats.admin_requisite?.bank_country || '-'}</p>
															</span>
															<span className="bank-info  bank-info--item">
																<span>SWIFT / BIC</span>
																<p>{transactions_fiats.admin_requisite?.swift_bic}</p>
															</span>
															<span className="bank-info  bank-info--item">
																<span>Account Number</span>
																<p>{transactions_fiats.admin_requisite?.account_number}</p>
															</span>
														</span>
														<span className="bank-info  bank-info--text">
															<span>Notes</span>
															<p>{transactions_fiats.admin_requisite?.note}</p>
														</span>
													</span>
												</span>
											</span>
										</span>
									)}
								</span>
								<span className="td-more-info">
									{transactions_fiats.admin_requisite?.account_number}
								</span>
							</>
						)}
					</>
				)}
			</div>

			{/* {assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p
						className={
							assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
						}
					>
						From <span className="td-bold-text">{from}</span>
					</p>
					<p
						className={
							assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
						}
					>
						To <span className="td-bold-text">{to}</span>
					</p>
				</div>
			)} */}

			{assetType === EPendingWithdrawalType.CRYPTO && (
				<>
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(ext_tx_hash, 4, 4) || '-'}
						</span>
						{!!ext_tx_hash && (
							<CopyToClipboard text={ext_tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
											fill="#5974FF"
										/>
									</svg>
									Copy
								</button>
							</CopyToClipboard>
						)}
					</p>
				</>
			)}

			<div className="td">
				<p className="td-hidden-name">Tx ID</p>
				{assetType === EPendingWithdrawalType.CRYPTO ? (
					<>
						{user_id && id ? (
							<Link
								to={{
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									pathname: `${path}/${id}`,
									state: { fromPendingDepositCrypto: true, txId: id, userId: user_id },
								}}
							>
								{id}
							</Link>
						) : (
							<p>{id}</p>
						)}
					</>
				) : (
					<Link
						to={{
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							pathname: `${path}/${id}`,
							state: { fromPendingDepositFiat: true, txId: id, userId: user_id },
						}}
					>
						{id}
					</Link>
				)}
			</div>

			{/* <div className="td">
				<p className="td-hidden-name">Admin Notes</p>
				{editMode ? (
					<div className="input">
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Notes"
								value={editedNote || ''}
								onChange={handleChangeEditedNote}
								className="input-item input-item--type-td"
							/>
						</div>
					</div>
				) : (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">{note ? `${note?.slice(0, 10) as string}...` : '-'}</p>
						<div className="tooltip-note">
							<p>{note || '-'}</p>
							<i />
						</div>
					</div>
				)}
			</div> */}

			{assetType === EPendingWithdrawalType.CRYPTO ? (
				<div className="td">
					<p className="td-hidden-name">Status</p>
					{editMode && status.status === EApiTransactionStatus.ADMIN_PENDING ? (
						<TableItemSelect
							activeElement={editedStatus}
							selectArray={statusArrayToEdit}
							onChange={handleEditStatus}
						/>
					) : (
						<span
							className={
								status.status === EApiTransactionStatus.ADMIN_PENDING
									? 'pending-status'
									: `${String(status.status)}-status`
							}
						>
							{convertStatusValue(status.status)}
						</span>
					)}
				</div>
			) : (
				<div className="td">
					<p className="td-hidden-name">Status</p>
					{editMode && status.status === 'pending' ? (
						<TableItemSelect
							activeElement={editedStatus}
							selectArray={statusArrayToEdit}
							onChange={handleEditStatus}
						/>
					) : (
						<span
							className={
								status.status === EApiTransactionStatus.ADMIN_PENDING
									? 'pending-status'
									: `${String(status.status)}-status`
							}
						>
							{convertStatusValue(status.status)}
						</span>
					)}
				</div>
			)}

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn--icon btn--cancel"
							onClick={handleDisableEditMode}
						/>
						{assetType === EPendingWithdrawalType.CRYPTO ? (
							<button
								type="button"
								className="btn--icon btn--check"
								onClick={handleEdit}
								disabled={
									editedStatus.value === EApiPooledDepositTransactionStatus.ADMIN_PENDING &&
									client_notes === editedNote
								}
							/>
						) : (
							<button
								type="button"
								className="btn--icon btn--check"
								onClick={handleEdit}
								disabled={editedStatus.value === 'pending' && client_notes === editedNote}
							/>
						)}
					</div>
				)}
				{permission && !editMode && (
					<>
						{assetType === EPendingWithdrawalType.CRYPTO ? (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
							<button
								type="button"
								className="btn btn--icon btn--edit"
								onClick={enableEditMode}
								disabled={status.status !== EApiTransactionStatus.ADMIN_PENDING}
							></button>
						) : (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
							<button
								type="button"
								className="btn btn--icon btn--edit"
								onClick={enableEditMode}
								disabled={status.status !== 'pending'}
							></button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PendingDepositsTableRow;
