import React, { FC, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDepositAccount } from 'redux/reducers/walletBalance/types';
import {
	addActiveBankAccounts,
	deleteActiveBankAccounts,
} from 'redux/reducers/bankAccounts/reducer';
import { getActiveBankAccounts } from 'redux/reducers/bankAccounts/selectors';

export interface IBankAccountsWithSelectRowPayload extends IDepositAccount {
	withoutAction?: boolean;
}

const BankAccountsWithSelectRow: FC<IBankAccountsWithSelectRowPayload> = ({
	id,
	bank_address,
	bank_name,
	beneficiary_address,
	beneficiary_name,
	swift_bic,
	account_number,
	note,
	asset,
	currency,
	withoutAction,
	status,
}) => {
	const dispatch = useDispatch();
	const activeBankAccounts = useSelector(getActiveBankAccounts);

	const handleActivateBankAccount = () => {
		dispatch(addActiveBankAccounts({ bank_account_id: id, asset_code: asset?.code || currency }));
	};

	const handleDisabledBankAccount = () => {
		dispatch(
			deleteActiveBankAccounts({ bank_account_id: id, asset_code: asset?.code || currency }),
		);
	};

	const isActiveFilter = useMemo(() => {
		return !!activeBankAccounts?.find((el) => el.bank_account_id === id);
	}, [activeBankAccounts, id]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Beneficiary Name</p>
				<p>{beneficiary_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Beneficiary Address</p>
				<p>{beneficiary_address}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Bank Name</p>
				<p>{bank_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Bank Address</p>
				<p>{bank_address}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{asset?.code?.toUpperCase() || currency?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">SWIFT/BIC</p>
				<p>{swift_bic}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Account Number</p>
				<p>{account_number}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Notes</p>
				<p>{note}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Enable/Disable</p>
				<div className="switch">
					<label className={withoutAction ? 'switch__label disabled' : 'switch__label'}>
						<input
							type="checkbox"
							className="hidden"
							onChange={isActiveFilter ? handleDisabledBankAccount : handleActivateBankAccount}
							checked={isActiveFilter}
							disabled={withoutAction}
						/>
						<span className="switch__toggler" />
					</label>
				</div>
			</div>
		</div>
	);
};

export default BankAccountsWithSelectRow;
