import { FC } from 'react';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const UserVerificationPopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	title,
	message,
	payload,
	confirmVerificationHandler,
}) => {
	const onConfirmClick = () => {
		closeModal();
		!!confirmVerificationHandler && confirmVerificationHandler(payload.status);
	};
	const onButtonClick = () => {
		confirmVerificationHandler ? onConfirmClick() : closeModal();
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header">
					<p className="popup-header__title">{title || 'Warning'}</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="warning-popup" />
				</div>
				{!!message && (
					<div className="popup-text">
						<p>{message}</p>
						<p>{payload.fullName}</p>

						<p>{payload.email}</p>
					</div>
				)}
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary" onClick={onButtonClick}>
							Confirm
						</button>
						<button
							type="button"
							className="btn-link btn--full justify-content-center"
							onClick={closeModal}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default UserVerificationPopUp;
