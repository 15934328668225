/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import { EWithdrawalLimitType, IApiWithdrawalLimit } from 'services/api/transactions/types';
import {
	putWithdrawalLimitsRequest,
	updateWithdrawalLimitsRequest,
} from 'redux/reducers/transactions/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
// import { handleFeeFixedChange } from 'services/utils/decimalLimits';

export interface ITransfersLimitsRowProps extends IApiWithdrawalLimit {
	type: EWithdrawalLimitType;
}

const TransfersLimitsRow: FC<ITransfersLimitsRowProps> = ({
	id,
	asset,
	minimum,
	maximum,
	hourly,
	daily,
	perm,
	type,
}) => {
	const dispatch = useDispatch();
	const handleDecimalCount = (value: string, assetType: string) => {
		let regexFiat = /^\d+(\.\d{0,2})?/;
		let regexCrypto = /^\d+(\.\d{0,5})?/;

		if (value.startsWith('.')) {
			regexFiat = /(\.\d{0,2})?/;
			regexCrypto = /(\.\d{0,5})?/;
		}

		const match = assetType === 'crypto' ? regexCrypto.exec(value) : regexFiat.exec(value);

		const fixedValue = match ? match[0] : value;
		return Number(fixedValue);
	};
	const [editedMinimum, setEditedMinimum] = useState<number | string>(minimum);
	const handleEditMinimum = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value);
		if ((asset.type === 'fiat' || asset.type === 'token') && value <= 999999.99) {
			setEditedMinimum(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
		if (asset.type === 'crypto' && value <= 999999.99999) {
			setEditedMinimum(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
	};
	const [editedMaximum, setEditedMaximum] = useState<number | string>(maximum);
	const handleEditMaximum = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value);
		if ((asset.type === 'fiat' || asset.type === 'token') && value <= 999999.99) {
			setEditedMaximum(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
		if (asset.type === 'crypto' && value <= 999999.99999) {
			setEditedMaximum(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
	};
	const [editedHourly, setEditedHourly] = useState<number | string>(hourly);
	const handleEditHourly = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value);
		if ((asset.type === 'fiat' || asset.type === 'token') && value <= 999999.99) {
			setEditedHourly(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
		if (asset.type === 'crypto' && value <= 999999.99999) {
			setEditedHourly(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
	};
	const [editedDaily, setEditedDaily] = useState<number | string>(daily);
	const handleEditDaily = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value);
		if ((asset.type === 'fiat' || asset.type === 'token') && value <= 999999.99) {
			setEditedDaily(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
		if (asset.type === 'crypto' && value <= 999999.99999) {
			setEditedDaily(
				event.target.value === '' ? '' : handleDecimalCount(event.target.value, asset.type),
			);
		}
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => setEditMode(true);
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		dispatch(
			putWithdrawalLimitsRequest({
				type,
				witdraw_limit_id: id,
				data: {
					daily: Number(editedDaily),
					hourly: Number(editedHourly),
					minimum: Number(editedMinimum),
					maximum: Number(editedMaximum),
				},
			}),
		);
		dispatch(
			updateWithdrawalLimitsRequest({
				id,
				data: {
					daily: Number(editedDaily),
					hourly: Number(editedHourly),
					minimum: Number(editedMinimum),
					maximum: Number(editedMaximum),
				},
			}),
		);
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedMaximum(maximum);
		setEditedMinimum(minimum);
		setEditedHourly(hourly);
		setEditedDaily(daily);
		disableEditMode();
	};

	useEffect(() => {
		setEditedMaximum(maximum);
		setEditedMinimum(minimum);
		setEditedHourly(hourly);
		setEditedDaily(daily);
	}, [daily, hourly, maximum, minimum]);

	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<div className="td td--currency">
					<img src={`/img/currencies/${asset?.code}.svg`} alt={asset?.name} />
					<p className="td-currency-name">{asset?.code?.toUpperCase() || '-'}</p>
					<span className="td-more-info">{asset?.name}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Minimum</p>
				{editMode ? (
					<input
						type="number"
						name="minimum"
						placeholder="0"
						onWheel={(event) => event.currentTarget.blur()}
						onChange={handleEditMinimum}
						value={editedMinimum}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<p>{roundingNumber(minimum, asset.code)}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Maximum</p>
				{editMode ? (
					<input
						type="number"
						name="maximum"
						placeholder="0"
						onWheel={(event) => event.currentTarget.blur()}
						onChange={handleEditMaximum}
						value={editedMaximum}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<p>{roundingNumber(maximum, asset.type)}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Hourly Limit</p>
				{editMode ? (
					<input
						type="number"
						name="hourly"
						placeholder="0"
						onWheel={(event) => event.currentTarget.blur()}
						onChange={handleEditHourly}
						value={editedHourly}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<p>{roundingNumber(hourly, asset.code)}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Daily Limit</p>
				{editMode ? (
					<input
						type="number"
						name="daily"
						placeholder="0"
						onChange={handleEditDaily}
						onWheel={(event) => event.currentTarget.blur()}
						value={editedDaily}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<p>{roundingNumber(daily, asset.code)}</p>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{perm && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn btn--icon btn--check"
							onClick={handleEdit}
							disabled={
								!(
									editedMaximum !== '' &&
									editedMinimum !== '' &&
									editedDaily !== '' &&
									editedHourly !== ''
								)
							}
						/>
						<button
							type="button"
							className="btn btn--icon btn--cancel"
							onClick={handleDisableEditMode}
						/>
					</div>
				)}
				{perm && !editMode && (
					// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
					<button
						type="button"
						className="btn btn--icon btn--edit"
						onClick={enableEditMode}
					></button>
				)}
			</div>
		</div>
	);
};

export default TransfersLimitsRow;
